@tailwind base;

@tailwind components;

@tailwind utilities;

html {
  font-size: 15px;
}

@layer utilities {
  .placeholder-weight-normal::placeholder {
    @apply font-normal;
  }
}

/**
REACT SELECT STYLING SORRY
 */
.reactSelectContainer {
  @apply w-full;

  .reactSelect__control {
    @apply min-h-[35.75px] rounded-md border-pgray-300;

    &--is-focused {
      @apply border border-pblue-500 shadow-none;
    }
  }

  .reactSelect__value-container {
    @apply py-[0.2rem] pl-3 text-sm font-medium text-pgray-700;
  }

  .reactSelect__input-container {
    @apply -ml-[0.05rem];
  }

  .reactSelect__input {
    @apply -ml-0.5 ring-0 focus:border-0 focus:ring-0;
  }

  .reactSelect__single-value {
    @apply ml-0;
  }

  .reactSelect__indicator-separator {
    @apply hidden;
  }

  .reactSelect__option {
    @apply mx-2 min-h-[2.75rem] w-auto rounded bg-white px-2 py-3 text-sm font-medium text-pgray-700;

    &--is-focused,
    &--is-selected {
      @apply bg-pgray-50;
    }
  }

  .reactSelect__placeholder {
    @apply -ml-[0.05rem] text-base text-sm font-normal text-pgray-300;
  }

  .reactSelect__menu-list-title {
    @apply mx-2 my-3 px-2 text-sm font-medium text-pgray-500;
  }

  .reactSelect__menu {
    @apply rounded-md border border-pgray-300 shadow-md;
  }

  .reactSelect__menu-notice {
    @apply text-sm font-medium text-pgray-300;
  }

  .reactSelect__multi-value {
    @apply bg-pgray-200;
  }

  .reactSelect__multi-value__label {
    @apply text-xs;
  }

  .reactSelect__multi-value__remove.reactSelect__multi-value__remove:hover {
    @apply bg-pred-200 text-pred-700;
  }

  .reactSelect__clear-indicator {
    @apply cursor-pointer text-pgray-300;
  }

  .reactSelect__clear-indicator:hover {
    @apply text-pgray-500;
  }

  &.hasError {
    .reactSelect__control {
      @apply border-pred-500;

      &--is-focused {
        @apply border-pred-700;
      }
    }
  }
}

.flashrAvatar {
  box-shadow: 5px 5px 10px #e5e9f1;
}

.flashrViewContainer {
  box-shadow: 20px 20px 60px #e5e9f1;
}

.flashrAddressShadow {
  box-shadow: 5px 5px 10px #e5e9f1, -5px -5px 10px #ffffff;
  border: 2px solid #f9fafb;
}
